import type { Theme } from '../../types/themes/theme'
import { themePropertyConverters } from '../../utils/converters/themePropertyConverters'
import EffectsToken from '../design-tokens/effect.styles.tokens.json'
import TypographyToken from '../design-tokens/text.styles.tokens.json'

import { borderRadius } from './borderRadius'
import { mediaQueries } from './mediaQueries'
import { opacity } from './opacity'
import { transitions } from './transitions'

export const commonTheme: Omit<Theme, 'colors'> = {
  borderRadius,
  effects: themePropertyConverters.effects(EffectsToken),
  mediaQueries,
  opacity,
  transitions,
  typography: themePropertyConverters.typography(TypographyToken),
}
