import 'dayjs/locale/de.js'
import 'dayjs/locale/es.js'
import 'dayjs/locale/fr.js'
import 'dayjs/locale/it.js'
import 'dayjs/locale/pt.js'
import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import Head from 'next/head'
import type { NextComponentType, NextPage, NextPageContext } from 'next/types'
import type { ReactElement, ReactNode } from 'react'
import { type FC } from 'react'
import { Provider } from 'react-redux'

import { wrapper } from '@redux/store'
import { useGetSiteInfoQuery } from '@reduxCommonApp/commonApp.api'

import { ApplicationNames } from '@constants/applications/applicationNames'
import ThemeProvider from '@hocs/providers/ThemeProvider'
import useCheckAuth from '@hooks/auth/useCheckAuth'
import useUserPageInteraction from '@hooks/global/useUserPageInteraction'
import GTMScripts from '@services/analytics/GTMScripts'
import getAppConfig from '@services/config/config'
import '@services/dayJs/prezerveTimeZone'
import CustomScripts from '@services/htmlScripts/CustomScripts'
import type { DefaultServerSidePropsType } from '@utils/serverSideProps/getDefaultServerSideProps'

import ServerSidePropsContext from './context/serverSidePropsContext'

const DMSans = localFont({
  display: 'swap',
  src: [
    {
      path: '../../../../../public/fonts/DMSans-Regular.woff2',
      style: 'normal',
      weight: 'normal',
    },
    {
      path: '../../../../../public/fonts/DMSans-Medium.woff2',
      style: 'normal',
      weight: '500',
    },
    {
      path: '../../../../../public/fonts/DMSans-MediumItalic.woff2',
      style: 'italic',
      weight: '500',
    },
    {
      path: '../../../../../public/fonts/DMSans-BoldItalic.woff2',
      style: 'italic',
      weight: '700',
    },
    {
      path: '../../../../../public/fonts/DMSans-Italic.woff2',
      style: 'italic',
      weight: 'normal',
    },
    {
      path: '../../../../../public/fonts/DMSans-Bold.woff2',
      style: 'normal',
      weight: '700',
    },
  ],
})

const { publicRuntimeConfig } = getAppConfig()

const fontFamilyByAppName: Record<ApplicationNames, string> = {
  [ApplicationNames.HST]: `${DMSans.style.fontFamily}, sans-serif !important`,
  [ApplicationNames.RN_CLASSIC]: `Arial, sans-serif !important`,
  [ApplicationNames.RN_INTERIM]: `Arial, sans-serif !important`,
  [ApplicationNames.RN_MODERN]: `Arial, sans-serif !important`,
  [ApplicationNames.RN_NEW]: `Arial, sans-serif !important`,
  [ApplicationNames.SEARCH_WIDGET]: `${DMSans.style.fontFamily}, sans-serif !important`,
}

export type NextPageWithLayout<P, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, serverSideProps: P) => ReactNode
}

type AppPropsWithLayout = AppProps<DefaultServerSidePropsType> & {
  Component: NextPageWithLayout<DefaultServerSidePropsType>
}

const MyAppContent: FC<{
  Component: NextComponentType<NextPageContext<any>, any, any>
  pageProps: DefaultServerSidePropsType
}> = ({ Component, pageProps }) => {
  useUserPageInteraction()
  useGetSiteInfoQuery(false)
  useCheckAuth()

  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style global jsx>{`
        html,
        body,
        * {
          font-family: ${fontFamilyByAppName[publicRuntimeConfig.site]};

          input {
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              font-family: ${fontFamilyByAppName[publicRuntimeConfig.site]};
            }
          }
        }

        #__next {
          height: 100%;
        }
      `}</style>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" name="viewport" />
        <link href="/images/logo/logoShort.svg" rel="shortcut icon" />
      </Head>
      <GTMScripts />
      {publicRuntimeConfig.customScripts && <CustomScripts scriptsList={publicRuntimeConfig.customScripts} />}
      <Component {...pageProps} />
    </>
  )
}

const MyApp = ({ Component, ...rest }: AppPropsWithLayout) => {
  const { props, store } = wrapper.useWrappedStore(rest)
  const getLayout = Component.getLayout ?? (page => page)

  return (
    <Provider store={store}>
      <ThemeProvider antdLocale={rest.pageProps.antdLocale}>
        <ServerSidePropsContext.Provider value={props.pageProps}>
          {getLayout(<MyAppContent Component={Component} pageProps={props.pageProps} />, rest.pageProps)}
        </ServerSidePropsContext.Provider>
      </ThemeProvider>
    </Provider>
  )
}

export default MyApp
